/**
 * Entry point for rendering the React application.
 */

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import "simplebar/dist/simplebar.css";
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";
import App from "./App";
import { store } from "store";
// import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);

// const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReduxProvider store={store}>
    {/* <ConfigProvider> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </ConfigProvider> */}
  </ReduxProvider>
);

reportWebVitals();
