import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { dispatch } from "store";

/**
 * Initial state for the order slice.
 */
const initialState = {
  isOpen: false,
  open: false,
  orders: [],
  order: null,
  error: null,
  alertPopup: false,
};

/**
 * Redux slice for managing orders.
 * @type {Slice}
 */
const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    // review order popup
    reviewInvoicePopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    // handler customer form popup
    selectCountry(state, action) {
      state.country = action.payload.country;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all order order
    getOrders(state, action) {
      state.orders = action.payload;
    },

    // get order details
    getSingleOrder(state, action) {
      state.order = action.payload;
    },

    // create order
    createOrder(state, action) {
      state.orders = [...state.orders, action.payload];
    },

    // update order
    UpdateOrder(state, action) {
      const { NewOrder } = action.payload;
      const OrderUpdate = state.orders.map((item) => {
        if (item.id === NewOrder.id) {
          return NewOrder;
        }
        return item;
      });
      state.orders = OrderUpdate;
    },

    // delete order
    deleteOrder(state, action) {
      const { orderId } = action.payload;
      const deleteOrder = state.orders.filter((order) => order.id !== orderId);
      state.orders = deleteOrder;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
  },
});

export default order.reducer;

export const {
  reviewInvoicePopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getOrders,
  alertPopupToggle,
} = order.actions;

/**
 * Function to subscribe to order updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToOrders = () => {
  const unsubscribeFunction = onSnapshot(collection(firestore, "orders"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(order.actions.getOrders(data));
  });

  return unsubscribeFunction;
};

/**
 * Function to create a new order in Firestore.
 * @async
 * @param {object} order - The order object to be created.
 * @param {string} invoiceNumber - The current invoice number.
 */
export async function createOrder(order, invoiceNumber) {
  try {
    const docRef = doc(collection(firestore, "orders"), invoiceNumber);
    order.id = invoiceNumber;
    order.statusUpdated = new Date().toLocaleDateString("en-GB").replaceAll("/", ".");
    await setDoc(docRef, order);

    // Increment invoice number and convert back to string
    let n = parseInt(invoiceNumber) + 1;
    updateDoc(doc(firestore, "data/invoice"), {
      invoiceNumber: n.toString(),
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to update an existing order in Firestore.
 * @async
 * @param {object} order - The updated order object.
 */
export async function updateOrder(order) {
  try {
    const docRef = doc(collection(firestore, "orders"), order.id);
    order.statusUpdated = new Date().toLocaleDateString("en-GB").replaceAll("/", ".");
    await updateDoc(docRef, order);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to delete an order from Firestore.
 * @async
 * @param {string} id - The ID of the order to be deleted.
 */
export async function deleteOrder(id) {
  try {
    const docRef = doc(collection(firestore, "orders"), id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
  }
}
