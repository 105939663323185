import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { dispatch } from "store";

const initialState = {
  userList: [],
  user: null,
  error: null,
  alertPopup: false,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all users
    getUsers(state, action) {
      state.userList = action.payload;
    },

    // get single user
    getSingleUser(state, action) {
      state.user = action.payload;
    },

    // create user
    createUser(state, action) {
      state.userList = [...state.userList, action.payload];
    },

    // update user
    UpdateUser(state, action) {
      const { NewUser } = action.payload;
      const UserUpdate = state.userList.map((item) => {
        if (item.id === NewUser.id) {
          return NewUser;
        }
        return item;
      });
      state.userList = UserUpdate;
    },

    // delete user
    deleteUser(state, action) {
      const { userId } = action.payload;
      const deleteUser = state.userList.filter((user) => user.id !== userId);
      state.userList = deleteUser;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
  },
});

export default user.reducer;

export const {
  reviewUserPopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getUsers,
  alertPopupToggle,
} = user.actions;

export const getUserList = () => {
  return async (dispatch) => {
    try {
      const ref = collection(firestore, "users");
      const querySnapshot = await getDocs(ref);
      const users = querySnapshot.docs.map((doc) => doc.data());
      dispatch(user.actions.getUsers(users)); // Dispatch serializable data
    } catch (error) {
      console.error(error);
      dispatch(user.actions.hasError(error));
    }
  };
};

export function getUserInsert(NewsaleList) {
  throw new Error("Error: getUserInsert not implemented");
}

export function getUserUpdate(NewsaleList) {
  throw new Error("Error: getUserUpdate not implemented");
}

export function getUserDelete(userId) {
  throw new Error("Error: getUserDelete not implemented");
}

export const getUser = (userId) => {
  return async (dispatch) => {
    try {
      const ref = collection(firestore, "users");
      const querySnapshot = await getDocs(ref);
      const users = querySnapshot.docs.map((doc) => doc.data());
      const targetUser = users.find((user) => user.id === userId);
      dispatch(user.actions.getSingleUser(targetUser)); // Dispatch serializable data
    } catch (error) {
      console.error(error);
      dispatch(user.actions.hasError(error));
    }
  };
};

export const subscribeToUser = (userId) => {
  const q = query(collection(firestore, "users"), where("id", "==", userId));
  const unsubscribeFunction = onSnapshot(q, (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(user.actions.getUsers(data[0]));
  });

  return unsubscribeFunction;
};
