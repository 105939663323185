export const twitterColor = "#1DA1F2";
export const facebookColor = "#3b5998";
export const linkedInColor = "#0e76a8";

export const APP_DEFAULT_PATH = "/";
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;

export const ThemeMode = {
  LIGHT: "light",
  DARK: "dark",
};

export const MenuOrientation = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

export const ThemeDirection = {
  LTR: "ltr",
  RTL: "rtl",
};

/**
 * Configuration object for theme settings.
 */
const config = {
  fontFamily: `'Roboto', sans-serif`,
  i18n: "en",
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: "default",
  themeDirection: ThemeDirection.LTR,
};

export default config;
