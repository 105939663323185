// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  HeartOutlined,
  RocketOutlined,
  FireOutlined,
  TagOutlined,
  GithubOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  RocketOutlined,
  FireOutlined,
  BorderOutlined,
  HeartOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  TagOutlined,
  GithubOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GlobalOutlined,
};

const other = {
  id: "links",
  title: <FormattedMessage id="Links" />,
  type: "group",
  children: [
    {
      id: "firebase-console",
      title: <FormattedMessage id="Firebase Console" />,
      type: "item",
      url: "https://console.firebase.google.com/u/0/project/development-db-e60de/overview",
      icon: icons.ChromeOutlined,
      target: true,
    },
    {
      id: "whatsapp",
      title: <FormattedMessage id="Whatsapp" />,
      type: "item",
      url: "https://web.whatsapp.com/",
      icon: icons.WhatsAppOutlined,
      target: true,
    },
    {
      id: "instagram",
      title: <FormattedMessage id="Instagram" />,
      type: "item",
      url: "https://www.instagram.com/",
      icon: icons.InstagramOutlined,
      target: true,
    },
    {
      id: "github",
      title: <FormattedMessage id="Github" />,
      type: "item",
      url: "https://github.com/nordsoy",
      icon: icons.GithubOutlined,
      target: true,
    },
    {
      id: "homepage",
      title: <FormattedMessage id="nords.fi" />,
      type: "item",
      url: "https://www.nords.fi/",
      icon: icons.GlobalOutlined,
      target: true,
    },
  ],
};

export default other;
