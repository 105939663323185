import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import { collection, onSnapshot } from "firebase/firestore";
import { dispatch } from "store";

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  sales: [],
  sale: null,
  error: null,
  alertPopup: false,
};

const sale = createSlice({
  name: "sale",
  initialState,
  reducers: {
    // review sale popup
    reviewSalePopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    // handler customer form popup
    selectCountry(state, action) {
      state.country = action.payload.country;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all sales
    getSales(state, action) {
      state.sales = action.payload;
    },

    // get sale details
    getSingleSale(state, action) {
      state.sale = action.payload;
    },

    // create sale
    createSale(state, action) {
      state.sales = [...state.sales, action.payload];
    },

    // update sale
    UpdateSale(state, action) {
      const { NewSale } = action.payload;
      const SaleUpdate = state.sales.map((item) => {
        if (item.id === NewSale.id) {
          return NewSale;
        }
        return item;
      });
      state.sales = SaleUpdate;
    },

    // delete sale
    deleteSale(state, action) {
      const { saleId } = action.payload;
      const deleteSale = state.sales.filter((sale) => sale.id !== saleId);
      state.sales = deleteSale;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
  },
});

export default sale.reducer;

export const {
  reviewSalePopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getSales,
  alertPopupToggle,
} = sale.actions;

export const subscribeToSales = () => {
  const unsubscribeFunction = onSnapshot(collection(firestore, "sales"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(sale.actions.getSales(data));
  });

  return unsubscribeFunction;
};

export function getSaleInsert(NewsaleList) {
  throw new Error("Error: getSaleInsert not implemented");
}

export function getSaleUpdate(NewsaleList) {
  throw new Error("Error: getSaleUpdate not implemented");
}

export function getSaleDelete(saleId) {
  throw new Error("Error: getSaleDelete not implemented");
}
