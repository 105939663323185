// project import
import axios from "utils/axios";
import { dispatch } from "store";

// third-party
import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  dataList: [],
  data: null,
  invoiceNumber: null,
  error: null,
  alertPopup: false,
};

const data = createSlice({
  name: "data",
  initialState,
  reducers: {
    // review data popup
    reviewLeadPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all data data
    getIncome(state, action) {
      state.dataList = action.payload;
    },

    // get invoice number
    getInvoiceNumber(state, action) {
      state.invoiceNumber = action.payload;
    },

    // get data details
    getSingleList(state, action) {
      state.data = action.payload;
    },

    // create data
    createData(state, action) {
      state.dataList = [...state.dataList, action.payload];
    },

    // update data
    UpdateData(state, action) {
      const { NewData } = action.payload;
      const DataUpdate = state.dataList.map((item) => {
        if (item.id === NewData.id) {
          return NewData;
        }
        return item;
      });
      state.dataList = DataUpdate;
    },

    // delete data
    deleteData(state, action) {
      const { invoiceId } = action.payload;
      const deleteData = state.dataList.filter((data) => data.id !== invoiceId);
      state.dataList = deleteData;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
  },
});

export default data.reducer;

export const {
  reviewLeadPopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getIncome,
  alertPopupToggle,
} = data.actions;

export const subscribeToDataRevenue = () => {
  const unsubscribeFunction = onSnapshot(
    collection(firestore, `data/revenue/data_${new Date().getFullYear()}`),
    (snapshot) => {
      const d = snapshot.docs.map((doc) => doc.data());
      dispatch(data.actions.getIncome(d));
    }
  );

  return unsubscribeFunction;
};

export const getDataInvoiceNumber = () => {
  return async (dispatch) => {
    try {
      // get invoice document reference from collection data
      const documentReference = doc(firestore, "data/invoice");
      // get field value of invoiceNumber from documentReference
      const documentSnapshot = await getDoc(documentReference);
      const number = documentSnapshot.data().invoiceNumber;
      // dispatch value of field invoiceNumber
      dispatch(data.actions.getInvoiceNumber(number));
    } catch (error) {
      console.error(error);
      dispatch(data.actions.hasError(error));
    }
  };
};

export function getDataInsert(NewLists) {
  return async () => {
    try {
      const response = await axios.post("/api/data/insert", { data: NewLists });
      dispatch(data.actions.createData(response.data));
    } catch (error) {
      dispatch(data.actions.hasError(error));
    }
  };
}

export function getFreshDataUpdate(NewLists) {
  return async () => {
    try {
      const response = await axios.post("/api/data/update", NewLists);
      dispatch(data.actions.UpdateData(response.data));
    } catch (error) {
      dispatch(data.actions.hasError(error));
    }
  };
}

export function getDataDelete(invoiceId) {
  return async () => {
    try {
      await axios.post("/api/data/delete", { invoiceId });
      dispatch(data.actions.deleteData({ invoiceId }));
    } catch (error) {
      dispatch(data.actions.hasError(error));
    }
  };
}

export function getDataSingleList(invoiceId) {
  return async () => {
    try {
      const response = await axios.post("/api/data/single", { id: invoiceId });
      dispatch(data.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(data.actions.hasError(error));
    }
  };
}
