// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  HeartOutlined,
  RocketOutlined,
  FireOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  RocketOutlined,
  FireOutlined,
  BorderOutlined,
  HeartOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  TagOutlined,
  UserOutlined,
};

const main = {
  id: "main",
  title: <FormattedMessage id="Main" />,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
    },
    {
      id: "orders",
      title: <FormattedMessage id="Orders" />,
      type: "item",
      url: "/orders",
      icon: icons.HeartOutlined,
    },
    {
      id: "sales",
      title: <FormattedMessage id="Sales" />,
      type: "item",
      url: "/sales",
      icon: icons.TagOutlined,
    },
    {
      id: "freshLeads",
      title: <FormattedMessage id="Fresh Leads" />,
      type: "item",
      url: "/freshLeads",
      icon: icons.RocketOutlined,
    },
    {
      id: "oldLeads",
      title: <FormattedMessage id="Old Leads" />,
      type: "item",
      url: "/oldLeads",
      icon: icons.FireOutlined,
    },
    {
      id: "profile",
      title: <FormattedMessage id="Profile" />,
      type: "item",
      url: "/profile",
      icon: icons.UserOutlined,
    },
  ],
};

export default main;
