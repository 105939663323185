import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import Loader from "components/Loader";
import { getFirestore } from "firebase/firestore";
import { getStorage, getDownloadURL, uploadBytes, ref } from "firebase/storage";

var app = null;

const config = require("./googleFirebaseConfig.json");

// firebase initialize
if (!firebase.apps.length) {
  app = firebase.initializeApp(config);
}

export const firestore = getFirestore(app);

export const storage = getStorage(app);

export const getInvoiceURL = (id) => {
  return getDownloadURL(ref(storage, "invoices/" + id + "_invoice.pdf"));
};

export const uploadInvoice = (id, file) => {
  if (file == null) return;
  uploadBytes(ref(storage, "invoices/" + id + "_invoice.pdf"), file);
};

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email,
                name: user.displayName || "Joose Lohi",
                role: "UI/UX Designer",
              },
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseTwitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseTwitterSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
