// third-party
import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  limit,
  onSnapshot,
  query,
} from "firebase/firestore";
import { dispatch } from "store";

const countries = [
  { code: "US", label: "United States Dollar", currency: "Dollar", prefix: "$" },
  { code: "GB", label: "United Kingdom Pound", currency: "Pound", prefix: "£" },
  { code: "IN", label: "India Rupee", currency: "Rupee", prefix: "₹" },
  { code: "JP", label: "Japan Yun", currency: "Yun", prefix: "¥" },
];

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  country: countries[0],
  countries: countries,
  freshLeadList: [],
  freshLead: null,
  error: null,
  alertPopup: false,
  unsubscribeFunction: null,
};

const freshLead = createSlice({
  name: "freshLead",
  initialState,
  reducers: {
    // review freshLead popup
    reviewLeadPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    // handler customer form popup
    selectCountry(state, action) {
      state.country = action.payload.country;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all freshLead freshLead
    getFreshLeads(state, action) {
      state.freshLeadList = action.payload;
    },

    // get freshLead details
    getSingleList(state, action) {
      state.freshLead = action.payload;
    },

    // create freshLead
    createFreshLead(state, action) {
      state.freshLeadList = [...state.freshLeadList, action.payload];
    },

    // update freshLead
    UpdateFreshLead(state, action) {
      const { NewFreshLead } = action.payload;
      const FreshLeadUpdate = state.freshLeadList.map((item) => {
        if (item.id === NewFreshLead.id) {
          return NewFreshLead;
        }
        return item;
      });
      state.freshLeadList = FreshLeadUpdate;
    },

    // delete freshLead
    deleteFreshLead(state, action) {
      const { invoiceId } = action.payload;
      const deleteFreshLead = state.freshLeadList.filter((freshLead) => freshLead.id !== invoiceId);
      state.freshLeadList = deleteFreshLead;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },

    setUnsubscribeFunction(state, action) {
      state.unsubscribeFunction = action.payload;
    },
  },
});

export default freshLead.reducer;

export const {
  reviewLeadPopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getFreshLeads,
  alertPopupToggle,
  setUnsubscribeFunction,
} = freshLead.actions;

export const subscribeToFreshLeads = () => {
  const q = query(
    collection(firestore, "leads/data/fresh_leads"),
    limit(100) // Limit the query to 200 documents
  );

  const unsubscribeFunction = onSnapshot(q, (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(freshLead.actions.getFreshLeads(data));
  });

  return unsubscribeFunction;
};

export const unsubscribeFromFreshLeads = () => {
  return async (dispatch, getState) => {
    const unsubscribeFunction = getState().freshLead.unsubscribeFunction;

    if (unsubscribeFunction) {
      unsubscribeFunction(); // Unsubscribe from Firestore
    }

    // Clear the unsubscribe function from Redux state
    dispatch(setUnsubscribeFunction(null));
  };
};

export async function getFreshLeadsCount() {
  const col = collection(firestore, "leads/data/fresh_leads");
  const snapshot = await getCountFromServer(col);
  return snapshot.data().count;
}

export function getFreshLeadInsert(NewLists) {
  throw new Error("Error: getFreshLeadInsert not implemented");
}

export function getFreshFreshLeadUpdate(NewLists) {
  throw new Error("Error: getFreshFreshLeadUpdate not implemented");
}

export function deleteFreshLead(id) {
  deleteDoc(doc(firestore, "leads/data/fresh_leads", id));
}

export function getFreshLeadSingleList(invoiceId) {
  throw new Error("Error: getFreshLeadSingleList not implemented");
}
