import PropTypes from "prop-types";

import logo from "assets/images/logo.png";

const LogoMain = ({ reverse }) => {
  return <img src={logo} alt="Nords Oy" width="120" />;
};

LogoMain.propTypes = {
  reverse: PropTypes.bool,
};

export default LogoMain;
