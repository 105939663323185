// material-ui
import { Stack, Typography } from "@mui/material";

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: "24px 16px 0px", mt: "auto" }}
  >
    <Typography variant="caption">&copy; Nords Oy 2024</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center"></Stack>
  </Stack>
);

export default Footer;
